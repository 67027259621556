
  import { ICheckoutPrep, ICheckoutSession, ITicketType } from "@/interfaces";
  import {
    dispatchPrepareCheckout,
    dispatchGetCheckoutEvent,
    dispatchCreateCheckoutSession,
  } from "@/store/main/actions";
  import {
    readCheckoutPrep,
    readCheckoutEvent,
    readCheckoutSession,
    readIsLoggedIn,
  } from "@/store/main/getters";
  import { Component, Vue, Watch } from "vue-property-decorator";
  import EventHeader from "@/components/EventHeader.vue";

  @Component({
    components: {
      EventHeader,
    },
  })
  export default class Event extends Vue {
    tickets: { tickettype: ITicketType; numSelected: number }[] = [];
    submitted = false;
    discountCode = "";

    get checkoutPrep() {
      return readCheckoutPrep(this.$store);
    }

    get checkoutEvent() {
      const event = readCheckoutEvent(this.$store);
      if (event && event.id === +this.$route.params.id) {
        return event;
      }
      return null;
    }

    get soldOut() {
      return this.checkoutEvent?.status === "soldout";
    }

    get checkoutSession() {
      return readCheckoutSession(this.$store);
    }

    get hasTickets() {
      return this.tickets.length;
    }

    get subTotal() {
      return this.tickets
        .map((t) => {
          return t.numSelected * t.tickettype.ticket_type_price;
        })
        .reduce((t1, t2) => t1 + t2)
        .toFixed(2);
    }

    get isLoggedIn() {
      return readIsLoggedIn(this.$store);
    }

    @Watch("checkoutPrep")
    public async onCheckoutPrepChanged(newCheckoutPrep: ICheckoutPrep | null) {
      if (newCheckoutPrep) {
        this.tickets = newCheckoutPrep.tickettypes.map((t) => ({
          tickettype: t,
          numSelected: 0,
        }));
      }
    }

    @Watch("checkoutSession")
    public async onCheckoutSessionChanged(newCheckoutSession: ICheckoutSession | null) {
      if (
        newCheckoutSession &&
        newCheckoutSession.event_id === this.checkoutEvent?.id
      ) {
        this.$router.push({
          name: "checkout",
          params: { id: `${newCheckoutSession.id}` },
        });
      }
    }

    public async mounted() {
      const eventId = +this.$router.currentRoute.params.id;
      await Promise.all([
        await dispatchPrepareCheckout(this.$store, {
          eventId,
        }),
        await dispatchGetCheckoutEvent(this.$store, {
          eventId,
        }),
      ]);
    }

    async onSubmit() {
      const ticketTypeId = this.tickets.find((t) => t.numSelected > 0)?.tickettype
        .ticket_type_id;
      const eventId = this.checkoutEvent?.id;
      if (ticketTypeId && eventId && this.isLoggedIn) {
        this.submitted = true;
        await dispatchCreateCheckoutSession(this.$store, {
          eventId,
          ticketTypeId,
          discountCode: this.discountCode,
        });
        this.submitted = false;
      } else if (!this.isLoggedIn) {
        this.$router.push({
          name: "login",
          query: { next: `/event/${eventId}` },
        });
      }
    }
  }
